import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

const App = () => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [pdfDetails, setPdfDetails] = useState({
    PdfFileName: '',
    PdfPassword: '',
    PdfFlag: '',
    FileName: '', 
    Flag: ''
  });




  

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file || file.type !== 'application/pdf') {
      setError('Please upload a valid PDF file.');
      setUploadedFile(null);
      return;
    }
    
 
    const filePath = `/home/pankajsanga/projects/uploads/${file.name}`;
  
    // Appending additional data to FormData
    const formData = new FormData();
    pdfDetails.PdfFileName = filePath;
    formData.append('file', file, pdfDetails.PdfFileName); 
    console.log('filename:' , filePath)
    formData.append('PdfPassword', pdfDetails.PdfPassword || ''); 
    formData.append('PdfFlag', pdfDetails.PdfFlag || ''); 
    
    setUploadedFile(file);
    setProcessing(true);
    setError(null);
  
    try {
      const response = await axios.post('http://103.178.113.214:3005/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      console.log('Processing complete:', response.data);
      setProcessing(false);
    } catch (error) {
      console.error('Error processing file:', error);
      setProcessing(false);
      setError('Error processing file. Please try again.');
    }
  };
  


  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const downloadFile = async () => {
    if (!uploadedFile) {
      setError('No file uploaded');
      return;
    }

    // perform get request here 
    try {
      const downloadResponse = await axios.get('download endpoint', {
        responseType: 'blob',
      });

      const blob = new Blob([downloadResponse.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'processed_file.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
      setError('Error downloading file. Please try again.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPdfDetails({ ...pdfDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform POST request
    try {
      const response = await axios.post('http://103.178.113.214:5175/PdfUnlock', pdfDetails);
      console.log('POST Request Response:', response.data);

      if (response.status === 200) {
        alert('Zero Success' + JSON.stringify(response.data));
      } else {
        console.error('Mission is not complete');
      }

    } catch (error) {
      console.error('Error in POST request:', error);
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <div {...getRootProps()} style={{ padding: '20px', border: '2px dashed #007bff', borderRadius: '5px', marginBottom: '20px' }}>
        <input {...getInputProps()} />
        <p style={{ color: '#007bff', fontSize: '18px' }}>
          {uploadedFile ? ` ${uploadedFile.name}` : 'Drag & drop a PDF file here, or click to select one'}
        </p>
      </div>

      {error && (
        <p style={{ color: 'red', fontSize: '18px', marginTop: '20px' }}>{error}</p>
      )}

      {uploadedFile && (
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="PdfPassword"
            value={pdfDetails.PdfPassword}
            onChange={handleInputChange}
            placeholder="Enter PDF Password"
            style={inputStyle}
          />
          <br />
          {processing ? (
            <p className='text-center' style={{ fontSize: '18px', marginTop: '20px' }}>Processing...</p>
          ) : (
            <>
              <button type="submit" style={buttonStyle}>Submit</button><br/>
              <button onClick={downloadFile} style={buttonStyle}>Download Processed PDF</button>
            </>
          )}
        </form>
      )}
    </div>
  );
};

const inputStyle = {
  padding: '10px',
  marginBottom: '10px',
  border: '1px solid #007bff',
  borderRadius: '5px',
  width: '80%',
  fontSize: '16px',
};

const buttonStyle = {
  padding: '10px 20px',
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  fontSize: '16px',
  cursor: 'pointer',
  marginTop: '20px',
};

export default App;